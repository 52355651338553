export const BASE_ROUTE = {
  ONBOARDING: "/onboarding",
  MY_MEDS: "/my-meds",
  CONSULTATIONS: "/consultations",
  PROFILE: "/profile",
  DOCUMENT_CENTER: "/document-center",
  COACHING: "/coaching",
  PROGRESS_TRACKER: "/progress-tracker",
  BILLING: "/billing",
} as const;

export const ROUTES = {
  BASE: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_USED: "/reset-password-used",
  FORGOT_PASSWORD: "/forgot-password",
  HOME: "/home",
  INTAKE_FORM: "/intake-form",
  CONSULTATIONS: {
    OVERVIEW: BASE_ROUTE.CONSULTATIONS,
    PURPOSE: `${BASE_ROUTE.CONSULTATIONS}/purpose`,
    FOLLOW_UP_FORM: `${BASE_ROUTE.CONSULTATIONS}/follow-up-form`,
    MEDICATION_REFILL_REQUEST: `${BASE_ROUTE.CONSULTATIONS}/medication-refill-request`,
    SCHEDULE: `${BASE_ROUTE.CONSULTATIONS}/schedule`,
  },
  COACHING: {
    OVERVIEW: BASE_ROUTE.COACHING,
    PAST_SESSIONS: `${BASE_ROUTE.COACHING}/past-sessions`,
    CREATE_SESSION: `${BASE_ROUTE.COACHING}/create-sessions`,
    EDIT_SESSION: `${BASE_ROUTE.COACHING}/edit-sessions/:sessionId`,
  },
  DOCUMENT_CENTER: {
    OVERVIEW: BASE_ROUTE.DOCUMENT_CENTER,
    LABS: `${BASE_ROUTE.DOCUMENT_CENTER}/labs`,
    PRESCRIPTIONS: `${BASE_ROUTE.DOCUMENT_CENTER}/previous-prescriptions`,
    MEDICAL_RECORDS: `${BASE_ROUTE.DOCUMENT_CENTER}/medical-records`,
    DOCTOR_NOTES: `${BASE_ROUTE.DOCUMENT_CENTER}/doctor-notes`,
    FRIDAYS_RESOURCES: `${BASE_ROUTE.DOCUMENT_CENTER}/fridays-resources`,
  },
  MESSAGES: "/messages",
  BILLING: {
    OVERVIEW: BASE_ROUTE.BILLING,
    CHANGE_SUBSCRIPTION: `${BASE_ROUTE.BILLING}/change-subscription`,
  },
  PROFILE: {
    OVERVIEW: `${BASE_ROUTE.PROFILE}`,
    EDIT: `${BASE_ROUTE.PROFILE}/edit`,
  },
  MY_MEDS: BASE_ROUTE.MY_MEDS,
  ONBOARDING: {
    WELCOME: `${BASE_ROUTE.ONBOARDING}/welcome`,
    MAIN_INFO: `${BASE_ROUTE.ONBOARDING}/main-info`,
    JOURNEY_BEGIN: `${BASE_ROUTE.ONBOARDING}/journey-begin`,
    PAYMENT_AND_ACCOUNT: `${BASE_ROUTE.ONBOARDING}/payment-and-account`,
    INTAKE_FORM: `${BASE_ROUTE.ONBOARDING}/intake-form`,
  },
} as const;

export const MODAL_ROUTES = {
  CONSULTATIONS: {
    REFILL_ALREADY_SCHEDULED: `${BASE_ROUTE.CONSULTATIONS}/refill-already-scheduled`,
    ALREADY_SCHEDULED: `${BASE_ROUTE.CONSULTATIONS}/already-scheduled`,
    REDIRECT_TO_SYNC: `${BASE_ROUTE.CONSULTATIONS}/redirect-to-sync`,
  },
  DOCUMENT_CENTER: {
    LABS: `${BASE_ROUTE.DOCUMENT_CENTER}/labs`,
    PRESCRIPTIONS: `${BASE_ROUTE.DOCUMENT_CENTER}/previous-prescriptions`,
    MEDICAL_RECORDS: `${BASE_ROUTE.DOCUMENT_CENTER}/medical-records`,
    DOCTOR_NOTES: `${BASE_ROUTE.DOCUMENT_CENTER}/doctor-notes`,
    REMOVE_DOCUMENT: `${BASE_ROUTE.DOCUMENT_CENTER}/remove-document`,
    EDIT_DOCUMENT: `${BASE_ROUTE.DOCUMENT_CENTER}/edit-document`,
    PREVIEW_DOCUMENT: `${BASE_ROUTE.DOCUMENT_CENTER}/preview-document`,
  },
  PROGRESS_TRACKER: {
    CREATE_ENTRY: `${BASE_ROUTE.PROGRESS_TRACKER}/create-entry`,
    EDIT_ENTRY: `${BASE_ROUTE.PROGRESS_TRACKER}/edit-entry`,
    DELETE_ENTRY: `${BASE_ROUTE.PROGRESS_TRACKER}/delete-entry`,
  },
  NEXT_STEPS: "/next-steps",
  BILLING: {
    EDIT_PAYMENT_METHOD: `${BASE_ROUTE.BILLING}/edit-payment-method`,
    CHANGE_SUBSCRIPTION: `${BASE_ROUTE.BILLING}/change-subscription`,
  },
} as const;

const EXTERNAL_BASE_LINK = "https://www.joinfridays.com";
export const EXTERNAL_LINK = {
  HOME: EXTERNAL_BASE_LINK,
  PRIVACY_AND_POLICY: `${EXTERNAL_BASE_LINK}/privacy-policy`,
  TERMS_AND_CONDITIONS: `${EXTERNAL_BASE_LINK}/terms-conditions`,
  TRACKING: "https://www.google.com/search?q=",
  SESH: "https://www.seshfitnessapp.com/landing/fridays-x-sesh",
  COUNSELING_WAIVER: "https://joinfridays.com/dietitianwaiver",
  BOOK_NUTRITIONAL_COUNSELING: "https://calendly.com/vy-joinfridays",
  FAQ: "https://intercom.help/joinfridays/en",
  FDA_DRUG_SHORTAGE:
    "https://dps.fda.gov/drugshortages/resolved/tirzepatide-injection",
  TUTORIALS: {
    MEDICATION_DOSING: "https://vimeo.com/1064924958",
    MEDICATION_REFILL: "https://vimeo.com/1064925217",
    COACHING_HUB: "https://vimeo.com/1064924878",
  },
} as const;
