import { analytics } from "@/App";
import type { OnboardingUser } from "@/shared.types";
import { handleQuoraTracking, QUORA_EVENTS } from "@/utils";
import { mergeCustomerIoProfiles } from "../api";
import { CUSTOMER_IO_EVENT_TYPES } from "../models";
import type {
  CheckoutPageViewedEvent,
  EmailSubmittedEvent,
  PaymentCompletedEvent,
  PhoneSubmittedEvent,
  SubscriptionItemClickedEvent,
} from "../models";

export const identifyOnboardingUser = async (user: OnboardingUser) => {
  await analytics.identify(user.code, {
    firstName: user.firstName,
    lastName: user.lastName,
    dateOfBirth: user.dateOfBirth,
    gender: user.gender,
    heightFeet: user.heightFeet,
    heightInches: user.heightInches,
    weight: user.weight,
  });
};

export const handleEmailSubmittedEvent = async ({
  user,
  formId,
  marketingConsent,
}: EmailSubmittedEvent) => {
  await analytics.alias(user.email, user.code);

  await Promise.all([
    analytics.identify(user.email, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      state: user.state,
      language: user.language,
      marketingConsent,
    }),
    analytics.track(CUSTOMER_IO_EVENT_TYPES.EMAIL_SUBMITTED, {
      userId: user.email,
      email: user.email,
      formId,
    }),
  ]);
};

export const handlePhoneNumberSubmittedEvent = async ({
  user,
  phoneNumber,
  formId,
  campaignId,
}: PhoneSubmittedEvent) => {
  await Promise.all([
    analytics.identify(user.email, {
      phone: phoneNumber,
    }),

    analytics.track(CUSTOMER_IO_EVENT_TYPES.SMS_SUBMITTED, {
      phoneNumber,
      campaignId,
      formId,
    }),
  ]);
};

export const handleSubscriptionItemClickedEvent = async ({
  user,
  plan,
}: SubscriptionItemClickedEvent) => {
  await analytics.track(CUSTOMER_IO_EVENT_TYPES.PRODUCT_ITEM_CLICKED, {
    userId: user.email,
    productId: plan.subscriptionPriceId,
    productName: plan.name,
    productFrequency: plan.durationInMonths,
    price: plan.price,
  });
};

export const handleCheckoutPageViewed = async ({
  user,
  plan,
}: CheckoutPageViewedEvent) => {
  await analytics.track(CUSTOMER_IO_EVENT_TYPES.CHECKOUT_PAGE_VIEWED, {
    userId: user.email,
    products: [
      {
        productId: plan.subscriptionPriceId,
        productName: plan.name,
        productFrequency: plan.durationInMonths,
        price: plan.price,
      },
    ],
    totalPrice: plan.price,
  });

  handleQuoraTracking({ event: QUORA_EVENTS.ADD_PAYMENT_INFO });
};

export const handlePaymentCompletedEvent = async ({
  user,
  payment,
  discountCode,
  plan,
  previousCustomerId,
}: PaymentCompletedEvent) => {
  await analytics.track(CUSTOMER_IO_EVENT_TYPES.PAYMENT_COMPLETED, {
    userId: user.email,
    paymentId: payment.id,
    amountPaidInCents: payment.amount,
    discountCode,
    products: [
      {
        productId: plan.subscriptionPriceId,
        productName: plan.name,
        productFrequency: plan.durationInMonths,
        price: plan.price,
      },
    ],
  });

  if (previousCustomerId) {
    mergeCustomerIoProfiles({
      userCode: user.code,
      previousCustomerIoId: previousCustomerId,
    });
  }
};
