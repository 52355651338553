import { useQueryClient } from "@tanstack/react-query";
import { NavLink, useLocation } from "react-router-dom";
import { t } from "ttag";

import { logout } from "@/api";
import { Logo } from "@/components";
import { ROUTES } from "@/router";
import { PORTAL_USER_TYPE } from "@/shared";
import { usePortalUserStore } from "@/stores";
import { Avatar, Button, LogoutIcon } from "@/ui";
import { tw } from "@/utils";
import { NeedHelpMenu, TutorialsMenu } from "./components";
import { getNavigation } from "./utils";

export const Sidebar = ({
  onCloseSidebar,
}: {
  onCloseSidebar?: () => void;
}) => {
  const { pathname: currentPath } = useLocation();
  const user = usePortalUserStore((state) => state.portalUser);

  const queryClient = useQueryClient();
  const hasCurrentPathName = (path: string) => {
    return currentPath.includes(path);
  };

  const canEditProfile = user?.type === PORTAL_USER_TYPE.PATIENT;
  const navigation = getNavigation(user?.type);
  return (
    <div className="relative flex h-dvh w-67 flex-col gap-8 overflow-y-auto bg-nature-08 px-10 py-14">
      <Logo className="h-9 max-w-fit shrink-0 self-start" />

      {user && (
        <nav className="grow">
          <ul className="flex h-full flex-col gap-6 text-salmon-01">
            <li className="grow">
              <ul className="flex flex-col gap-2">
                {navigation.map((item) => (
                  <li key={item.label} className="relative">
                    {item.comingSoon && (
                      <div className="px-absolute absolute -right-4 -top-1 flex h-4.5 w-9 items-center justify-center rounded border border-brown-01 bg-nature-09">
                        <p className="text-[8px] font-bold uppercase leading-[normal]">
                          {t`soon`}
                        </p>
                      </div>
                    )}
                    {!item.disabled &&
                    item.subscriptionStatus.includes(user.subscriptionStatus) &&
                    (!item.needsIntakeForm || user.intakeFormCompleted) ? (
                      <NavLink
                        to={item.path}
                        onClick={onCloseSidebar}
                        draggable="false"
                        className={tw(
                          "block rounded-lg p-2.5 font-medium",
                          hasCurrentPathName(item.path)
                            ? "bg-nature-10"
                            : "hover:bg-nature-09",
                        )}
                      >
                        {item.label}
                      </NavLink>
                    ) : (
                      <span className="block select-none p-2.5 font-medium text-nature-05">
                        {item.label}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <ul className="flex flex-col gap-1 font-medium">
                <li>
                  {canEditProfile ? (
                    <NavLink
                      to={ROUTES.PROFILE.OVERVIEW}
                      onClick={onCloseSidebar}
                      className={tw(
                        "flex items-center gap-1 truncate rounded-lg py-2.5 font-medium",
                        currentPath.includes(ROUTES.PROFILE.OVERVIEW)
                          ? "-mx-2.5 bg-nature-10 px-2.5"
                          : "underline-offset-2 hover:underline focus:underline",
                      )}
                    >
                      <Avatar name={user.name} variant="secondary" size="xs" />
                      <span
                        className="overflow-hidden text-ellipsis"
                        aria-hidden="true"
                      >
                        {user.name}
                      </span>
                    </NavLink>
                  ) : (
                    <div className="flex items-center gap-1 truncate rounded-lg py-2.5 font-medium">
                      <Avatar name={user.name} variant="secondary" size="xs" />
                      <span
                        className="overflow-hidden text-ellipsis"
                        aria-hidden="true"
                      >
                        {user.name}
                      </span>
                    </div>
                  )}
                </li>

                <li>
                  <NeedHelpMenu onCloseSidebar={onCloseSidebar} />
                </li>

                <li>
                  <TutorialsMenu />
                </li>

                <hr className="my-2.5 border-nature-07" />

                <li>
                  <Button
                    type="button"
                    size="lg"
                    variant="tertiary"
                    onClick={() => {
                      logout(queryClient);
                      onCloseSidebar?.();
                    }}
                    className="group gap-1 px-0 text-salmon-01 underline-offset-2 hover:text-salmon-01 hover:underline focus:underline focus:ring-0"
                  >
                    <LogoutIcon className="size-4 fill-salmon-01" />
                    {t`Log Out`}
                  </Button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};
