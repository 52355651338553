import type { ObjectValue } from "@/shared.types";

export const THE_OFFER_EVENTS = {
  VIEW_CONTENT: "ViewContent",
  ADD_TO_CART: "AddToCart",
  INITIATE_CHECKOUT: "InitiateCheckout",
  PURCHASE: "Purchase",
} as const;
type TheOfferEvent = ObjectValue<typeof THE_OFFER_EVENTS>;

interface TheOfferPurchaseEventPayload {
  order_id: string;
  amount: number;
}

interface TheOfferTrackingParams {
  event: TheOfferEvent;
  payload?: TheOfferPurchaseEventPayload;
}

export const handleTheOfferTracking = ({
  event,
  payload,
}: TheOfferTrackingParams) => {
  try {
    if (!theoffer) {
      throw new Error("The Offer not initialized");
    }

    theoffer.io("track", event, payload);
  } catch (error) {
    console.error(`Error tracking The Offer ${event} event`, error);
  }
};
