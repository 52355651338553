import { useNavigate } from "react-router-dom";
import { jt, t } from "ttag";

import { EXTERNAL_LINK, ROUTES } from "@/router";
import { usePortalUserStore } from "@/stores";
import { Banner, CardSkeleton, DollarMinimalisticIcon, Shape8 } from "@/ui";
import { useSessionsOverview } from "../hooks";

export const NutritionCounseling = () => {
  const { canBookCounselingQuery } = useSessionsOverview();
  const navigate = useNavigate();
  const userHasGym = usePortalUserStore((state) =>
    Boolean(state?.portalUser?.gym),
  );

  if (userHasGym) {
    return null;
  }

  if (canBookCounselingQuery.isLoading) {
    return <CardSkeleton color="nature" className="w-full" />;
  }

  if (canBookCounselingQuery.isError) {
    return null;
  }

  const canBook =
    canBookCounselingQuery.isSuccess && canBookCounselingQuery.data;

  const counselingLink = (
    <a
      key={EXTERNAL_LINK.COUNSELING_WAIVER}
      href={EXTERNAL_LINK.COUNSELING_WAIVER}
      target="_blank"
      rel="noreferrer"
      className="underline"
    >
      {t`counseling waiver`}
    </a>
  );

  return (
    <Banner.Root>
      <Banner.Background>
        <Shape8 className="size-60 translate-x-6 translate-y-12 -scale-x-100 self-start text-nature-04 opacity-40 lg:-translate-y-6" />
      </Banner.Background>

      <Banner.Content>
        <div className="flex flex-col gap-1">
          <Banner.Tagline>
            {canBook ? t`Save your spot` : t`Subscription update needed`}
          </Banner.Tagline>
          <Banner.Title>
            {canBook
              ? t`Book Your 1:1 nutritional counseling session today!`
              : t`Access to 1:1 nutritional counseling sessions`}
          </Banner.Title>
        </div>

        <Banner.Description>
          {canBook
            ? jt`By clicking "Book now," you agree that you have read and understand the nutritional ${
                counselingLink
              }`
            : t`This feature is only available for new subscriptions. Upgrade and get personalized tips and advice.`}
        </Banner.Description>
      </Banner.Content>

      {canBook ? (
        <Banner.ActionButton asChild size="lg">
          <a
            href={EXTERNAL_LINK.BOOK_NUTRITIONAL_COUNSELING}
            target="_blank"
            rel="noreferrer"
          >
            {t`Book now`}
          </a>
        </Banner.ActionButton>
      ) : (
        <Banner.ActionButton
          size="lg"
          onClick={() => navigate(ROUTES.BILLING.CHANGE_SUBSCRIPTION)}
        >
          <DollarMinimalisticIcon className="size-4" />
          {t`Change subscription`}
        </Banner.ActionButton>
      )}
    </Banner.Root>
  );
};
