import { publicAPI } from "@/api";
import type { ServiceResponse } from "@/api";
import type { OnboardingUser } from "@/shared.types";

interface CustomerMergeData {
  userCode: OnboardingUser["code"];
  previousCustomerIoId: string;
}

export const mergeCustomerIoProfiles = ({
  userCode,
  previousCustomerIoId,
}: CustomerMergeData) => {
  void publicAPI.post<ServiceResponse<void>>(
    `/patients/${userCode}/analytics/merge`,
    {
      previousCustomerId: previousCustomerIoId,
    },
  );
};
