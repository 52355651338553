import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { t } from "ttag";

import { OnboardingHeader } from "@/components";
import {
  OnboardingActionButtons,
  OnboardingLayout,
} from "@/domains/onboarding/components";
import { IconWrapper, InfoCircleIcon, Input } from "@/ui";
import {
  calculateBMI,
  handleTheOfferTracking,
  THE_OFFER_EVENTS,
} from "@/utils";
import { getBMIFormSchema } from "./startingPoint.schemas";
import type { BMIData } from "./startingPoint.types";

interface StartingPointFormProps {
  savedData?: BMIData | null;
  onCancel: () => void;
  onSubmit: (params: BMIData) => void;
}

export const StartingPointForm = ({
  savedData,
  onCancel,
  onSubmit,
}: StartingPointFormProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<BMIData>({
    resolver: zodResolver(getBMIFormSchema()),
    values: savedData ?? ({} as BMIData),
  });

  const { heightFeet, heightInches, weight } = watch();
  const bmiValue = calculateBMI({
    heightFeet,
    heightInches,
    weight,
  });

  const handleContinue = handleSubmit(() =>
    onSubmit({ heightFeet, heightInches, weight, value: bmiValue }),
  );

  useEffect(() => {
    handleTheOfferTracking({ event: THE_OFFER_EVENTS.VIEW_CONTENT });
  }, []);

  return (
    <OnboardingLayout>
      <div className="flex w-full flex-col items-center gap-6 pb-10 sm:gap-12 sm:pb-20">
        <OnboardingHeader
          title={t`Your main info`}
          description={t`(*) Mandatory field`}
        />

        <form className="flex w-full flex-col gap-10 sm:max-w-3xl sm:gap-12">
          <div className="flex flex-col gap-1.5">
            <Input
              compact={false}
              id="height-feet"
              label={t`Height (feet) *`}
              placeholder={t`E.g. 5`}
              {...register("heightFeet")}
              error={errors.heightFeet?.message}
            />
            <Input
              compact={false}
              id="height-inches"
              label={t`Height (inches) *`}
              placeholder={t`E.g. 3`}
              {...register("heightInches")}
              error={errors.heightInches?.message}
            />
            <Input
              compact={false}
              id="weightPounds"
              label={t`Weight (pounds) *`}
              placeholder={t`E.g. 286`}
              {...register("weight")}
              error={errors.weight?.message}
            />
            <div className="flex items-center gap-2 rounded-2xl bg-brown-03 p-4.5 text-sm font-medium text-nature-10">
              <IconWrapper size="sm">
                <InfoCircleIcon />
              </IconWrapper>
              {t`Your BMI Score is ${bmiValue}`}
            </div>
          </div>

          <OnboardingActionButtons
            continueLabel={t`Next`}
            onCancel={onCancel}
            onContinue={handleContinue}
          />
        </form>
      </div>
    </OnboardingLayout>
  );
};
