interface EverflowConvertEventParams {
  amount: number;
  offerId?: number;
}

export const handleEverflowConvertEvent = ({
  amount,
  offerId,
}: EverflowConvertEventParams) => {
  try {
    if (!window?.EF) throw new Error("Everflow not loaded");

    if (!offerId) throw new Error("Offer ID is not set");

    void window.EF.conversion({
      offer_id: offerId,
      amount,
    });
  } catch (error) {
    console.error("Error tracking Everflow event", error);
  }
};
