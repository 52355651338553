interface PixelConvertEventParams {
  value: number;
}

export const handlePixelConvertEvent = ({ value }: PixelConvertEventParams) => {
  try {
    if (!window?.fbq) throw new Error("PixelTracking not loaded");

    if (!value) throw new Error("Value is not set");

    window.fbq("track", "Purchase", { value, currency: "USD" });
  } catch (error) {
    console.error("Error tracking Pixel conversion event", error);
  }
};

export const handlePixelAddToCartEvent = () => {
  try {
    if (!window?.fbq) throw new Error("PixelTracking not loaded");

    window.fbq("track", "AddToCart");
  } catch (error) {
    console.error("Error tracking Pixel add to Cart event", error);
  }
};
