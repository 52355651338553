export const KATALYS_EVENT_TYPES = {
  CONVERT: "convert",
} as const;

interface ConvertEvent {
  items: {
    price: number;
    name: string;
    sku: string;
    discount?: {
      code: string;
      amount: number;
    };
  }[];
  orderId: string;
}

type TrackPayload = (typeof window._revoffers_track)[number];

export const handleKatalysConvertEvent = (payload: ConvertEvent): void => {
  try {
    _revoffers_track = window._revoffers_track ?? [];

    const trackPayload: Partial<TrackPayload> = {
      action: KATALYS_EVENT_TYPES.CONVERT,
      order_id: payload.orderId,
    };

    let saleValue = 0;

    payload.items.forEach(({ price, name, sku, discount }, index) => {
      const keyPrefix = `line_item_${index + 1}`;

      trackPayload[`${keyPrefix}_title`] = name;
      trackPayload[`${keyPrefix}_sku`] = sku;
      trackPayload[`${keyPrefix}_price`] = Number(price);

      saleValue += Number(price);

      if (discount) {
        const { code, amount } = discount;
        const discountKeyPrefix = "discount_1";

        trackPayload[`${discountKeyPrefix}_code`] = code;
        trackPayload[`${discountKeyPrefix}_amount`] = amount;
      }
    });

    trackPayload.sale_amount = saleValue;

    _revoffers_track.push(trackPayload as KatalysConversionTrackPayload);
  } catch (error) {
    console.error("Error tracking Katalys event", error);
  }
};
