import { t } from "ttag";

import { SeshLogo } from "@/assets";
import { EXTERNAL_LINK } from "@/router";
import { usePortalUserStore } from "@/stores";

export const SeshLinkAlert = () => {
  const userHasGym = usePortalUserStore((state) =>
    Boolean(state?.portalUser?.gym),
  );

  if (userHasGym) {
    return;
  }

  return (
    <a
      target="_blank"
      href={EXTERNAL_LINK.SESH}
      rel="noreferrer"
      className="-mx-6 flex items-center justify-center gap-6 border-b-2 border-[#FFA5E8] px-7 py-4 md:m-0 md:gap-4"
    >
      <img src={SeshLogo} alt="Sesh Logo" className="h-7" />
      <span className="text-center leading-5 text-[#641450]">
        <strong>{t`FREE`}</strong>
        {t` Program for Fridays Members`}
      </span>
    </a>
  );
};
