import { useWindowSize } from "@uidotdev/usehooks";
import { t } from "ttag";

import { EXTERNAL_LINK } from "@/router";
import { DropdownMenu, VideoCameraIcon } from "@/ui";
import { sidebarMenuVariants } from "../variants";
import { LinkItem } from "./LinkItem";

const { trigger, menuIcon, item, arrowIcon } = sidebarMenuVariants();

export const TutorialsMenu = () => {
  const { width } = useWindowSize();
  const isMobile = Boolean(width && width < 530);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger className={trigger()}>
        <VideoCameraIcon className={menuIcon()} />
        {t`Tutorials`}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align={isMobile ? "start" : "end"}
        side={isMobile ? "top" : "right"}
        sideOffset={isMobile ? 0 : 12}
        alignOffset={isMobile ? -12 : 0}
      >
        <DropdownMenu.Item asChild className={item()}>
          <LinkItem
            label={t`Questions about medication dosing?`}
            link={EXTERNAL_LINK.TUTORIALS.MEDICATION_DOSING}
          />
        </DropdownMenu.Item>

        <DropdownMenu.Separator />

        <DropdownMenu.Item asChild className={item()}>
          <LinkItem
            label={t`How to order a medication refill`}
            link={EXTERNAL_LINK.TUTORIALS.MEDICATION_REFILL}
          />
        </DropdownMenu.Item>

        <DropdownMenu.Separator />

        <DropdownMenu.Item asChild className={item()}>
          <LinkItem
            label={t`How to use the Fridays Coaching Hub`}
            link={EXTERNAL_LINK.TUTORIALS.COACHING_HUB}
          />
        </DropdownMenu.Item>

        <DropdownMenu.Arrow className={arrowIcon({ color: "brown" })} />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
