import { tv } from "tailwind-variants";

export const sidebarMenuVariants = tv({
  slots: {
    trigger:
      "flex items-center gap-1 py-2.5 underline-offset-2 hover:underline focus:underline data-[state=open]:underline",
    item: "flex items-center justify-between gap-4",
    menuIcon: "size-4",
    arrowIcon: "h-3 w-6 -translate-y-0.5",
    linkIcon: "size-4",
    label: "",
  },
  variants: {
    color: {
      nature: {
        label: "text-nature-08",
        linkIcon: "text-nature-08",
      },
      brown: {
        arrowIcon: "fill-brown-01",
      },
    },
  },
});
