import { useState } from "react";
import { t } from "ttag";

import { FullScreenAlert } from "@/components";
import { DisqualifiedActionButtons } from "@/domains/onboarding/components";
import { useNavigateWithCurrentQueryParams } from "@/domains/onboarding/hooks";
import { env } from "@/env";
import { ROUTES } from "@/router";
import { useOnboardingStore } from "@/stores";
import { CalendarFixedIconMono } from "@/ui";
import {
  handlePixelAddToCartEvent,
  handleQuoraTracking,
  handleTheOfferTracking,
  QUORA_EVENTS,
  THE_OFFER_EVENTS,
} from "@/utils";
import { OnboardingScheduleVisit } from "../JourneyBegin/sections";
import {
  HealthiePasswordForm,
  PaymentForm,
  SubscriptionPlanSelection,
} from "./sections";

const STEP = {
  PLAN_SELECTION: "plan-selection",
  PAYMENT_FORM: "payment-form",
  ACCOUNT_FORM: "account-form",
  PRIOR_CONSULTATION_ALERT: "prior-consultation-alert",
  SCHEDULE_CONSULTATION: "schedule-consultation",
} as const;

type Step = (typeof STEP)[keyof typeof STEP];

export const PaymentAndAccount = () => {
  const [step, setStep] = useState<Step>(STEP.PLAN_SELECTION);
  const navigateWithQuery = useNavigateWithCurrentQueryParams();
  const isAsync = useOnboardingStore((state) => !state.appointment);
  const selectedPlan = useOnboardingStore((state) => state.subscriptionPlan);
  const setAppointment = useOnboardingStore((state) => state.setAppointment);

  const needsPriorConsultation =
    isAsync &&
    selectedPlan?.subscriptionPriceId ===
      env.VITE_STRIPE_TIRZEPATIDE_MONTHLY_PRICE_ID;

  const onSubscriptionPlanSelectionContinue = () => {
    handlePixelAddToCartEvent();
    handleQuoraTracking({ event: QUORA_EVENTS.ADD_TO_CART });
    handleTheOfferTracking({ event: THE_OFFER_EVENTS.ADD_TO_CART });

    if (needsPriorConsultation) {
      return setStep(STEP.PRIOR_CONSULTATION_ALERT);
    }

    setStep(STEP.PAYMENT_FORM);
  };

  const handleGoBackSchedule = () => {
    setStep(STEP.PAYMENT_FORM);
    setAppointment(null);
  };

  const renderStep = () => {
    switch (step) {
      case STEP.PLAN_SELECTION:
        return (
          <SubscriptionPlanSelection
            onGoBack={() => navigateWithQuery(ROUTES.ONBOARDING.JOURNEY_BEGIN)}
            onContinue={onSubscriptionPlanSelectionContinue}
          />
        );
      case STEP.PAYMENT_FORM:
        return (
          <PaymentForm
            onGoBack={() => setStep(STEP.PLAN_SELECTION)}
            onSubmit={() => setStep(STEP.ACCOUNT_FORM)}
          />
        );
      case STEP.ACCOUNT_FORM:
        return (
          <HealthiePasswordForm
            onSubmit={() => {
              navigateWithQuery(ROUTES.ONBOARDING.INTAKE_FORM);
            }}
          />
        );
      case STEP.PRIOR_CONSULTATION_ALERT:
        return (
          <FullScreenAlert
            customIcon={
              <CalendarFixedIconMono className="size-24" color="salmon" />
            }
            title={t`Schedule Your Video Visit`}
            description={t`Based on the plan you selected, a video visit with a provider is required before we can proceed. Please schedule your appointment now to continue.`}
            customAction={
              <DisqualifiedActionButtons
                onGoBack={() => setStep(STEP.PLAN_SELECTION)}
                onContinue={() => setStep(STEP.SCHEDULE_CONSULTATION)}
              />
            }
          />
        );
      case STEP.SCHEDULE_CONSULTATION:
        return (
          <OnboardingScheduleVisit
            onGoBack={handleGoBackSchedule}
            onContinue={() => setStep(STEP.PAYMENT_FORM)}
          />
        );
    }
  };

  return renderStep();
};
