import { LinkIcon } from "@/ui";
import { sidebarMenuVariants } from "../variants";

const linkIconClassName = sidebarMenuVariants().linkIcon({ color: "nature" });

interface LinkItemProps {
  link: string;
  label: string;
}

export const LinkItem = ({ link, label, ...props }: LinkItemProps) => {
  return (
    <a href={link} rel="noreferrer noopener" target="_blank" {...props}>
      {label}
      <LinkIcon className={linkIconClassName} />
    </a>
  );
};
