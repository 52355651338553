import type { ObjectValue } from "@/shared.types";

export const TATARI_EVENT = {
  PURCHASE: "Purchase",
} as const;
type TatariEvent = ObjectValue<typeof TATARI_EVENT>;

interface TatariConvertEventPayload {
  value: number;
}

export const handleTatariConvertEvent = (
  event: TatariEvent,
  payload: TatariConvertEventPayload,
) => {
  try {
    if (!tatari) {
      throw new Error("Tatari not loaded");
    }

    void tatari.track(event, payload);
  } catch (error) {
    console.error("Error tracking Tatari event:", error);
  }
};
