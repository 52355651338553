import { t } from "ttag";
import { z } from "zod";

import { getSubscriptionStatusSchema } from "@/domains/billing/models";
import {
  APPOINTMENT_REQUIRED_STATES,
  AVAILABLE_LANGUAGE,
  PORTAL_USER_TYPE,
} from "./constants";

export const getAppointmentRequiredStatesSchema = () =>
  z.enum(APPOINTMENT_REQUIRED_STATES);

export const getAvailableLanguageSchema = () =>
  z.nativeEnum(AVAILABLE_LANGUAGE, {
    required_error: t`Please select your preferred language`,
    invalid_type_error: t`Invalid language type`,
  });

export const getPortalUserSchema = () =>
  z.object({
    patientId: z.string().nullable(),
    email: z.string().email(),
    name: z.string(),
    subscriptionStatus: getSubscriptionStatusSchema(),
    intakeFormCompleted: z.boolean(),
    preferredLanguage: getAvailableLanguageSchema(),
    isFromEmbeddables: z.boolean(),
    code: z.string().uuid(),
    needsChangePassword: z.boolean(),
    type: z.nativeEnum(PORTAL_USER_TYPE).nullable(),
    askForMedicationStatus: z.boolean(),
    gym: z.string().nullable(),
  });

export const paginationParamsSchema = z.object({
  page: z.number(),
  pageSize: z.number(),
});
