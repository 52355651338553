import type { ObjectValue } from "@/shared.types";

export const GOOGLE_TAG_MANAGER_EVENTS = {
  PURCHASE: "purchase",
} as const;

export interface DetailOption {
  transaction_id: string;
  value: number;
  currency: "USD";
  coupon?: string;
  items: [
    {
      item_id: string;
      item_name: string;
      price: number;
      quantity: number;
      coupon?: string;
      discount?: number;
    },
  ];
}

interface GoogleTagManagerTrackingParams {
  event: ObjectValue<typeof GOOGLE_TAG_MANAGER_EVENTS>;
  options?: DetailOption;
}

export const initGoogleTagManagerTracking = () => {
  try {
    if (!window.dataLayer) window.dataLayer = [];
  } catch (error) {
    console.error("Error initializing GoogleTagManager", error);
  }
};

export const handleGoogleTagManagerTracking = ({
  event,
  options,
}: GoogleTagManagerTrackingParams) => {
  try {
    if (!window.dataLayer) throw new Error("GoogleTagManager not initialized");

    window.dataLayer.push({ event, ...options });
  } catch (error) {
    console.error(`Error tracking GoogleTagManager ${event} event`, error);
  }
};
