import {
  CoachingHubHeader,
  CoachingHubTabs,
  OngoingSessions,
  UpcomingSession,
} from "@/domains/coaching/sections";
import { ScreenLayout } from "@/layouts";
import { usePortalUserStore } from "@/stores";
import { tw } from "@/utils";
import { NutritionCounseling, SeshLinkAlert } from "../components";

export const CoachingHub = () => {
  const userHasGym = usePortalUserStore((state) =>
    Boolean(state?.portalUser?.gym),
  );

  return (
    <ScreenLayout className={tw("gap-6", !userHasGym && "pt-0 md:pt-0")}>
      <SeshLinkAlert />

      <CoachingHubHeader />

      <NutritionCounseling />

      <OngoingSessions />

      <UpcomingSession />

      <CoachingHubTabs />
    </ScreenLayout>
  );
};
