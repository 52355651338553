import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { OnboardingHeader } from "@/components";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import { useJourneyRequirements } from "@/domains/embeddables-onboarding/hooks";
import type { ChooseJourney } from "@/domains/embeddables-onboarding/models";
import { getChooseJourneySchema } from "@/domains/embeddables-onboarding/models";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { JOURNEY } from "@/shared.constants";
import { AltArrowLeftIcon, AltArrowRightIcon, Button, Form } from "@/ui";
import { JourneyRadioGroup } from "./components";

export const JourneySelection = () => {
  const {
    chooseJourneyFormValues,
    actions: {
      setChooseJourneyFormValues,
      setScheduleVideoConsultationFormValues,
    },
  } = useEmbeddablesForms();

  const form = useForm<ChooseJourney>({
    resolver: zodResolver(getChooseJourneySchema()),
    values: chooseJourneyFormValues,
  });

  const { hasPreviousRequestFilter } = useJourneyRequirements();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<ChooseJourney> = (values) => {
    setChooseJourneyFormValues(values);

    if (values.journey === JOURNEY.VIDEO_CONSULTATION) {
      return navigate(EMBEDDABLES_ONBOARDING_ROUTE.CHOOSE_JOURNEY_SCHEDULE);
    }

    if (values.journey === JOURNEY.MEDICATION_REQUEST) {
      setScheduleVideoConsultationFormValues(undefined);

      if (hasPreviousRequestFilter) {
        setChooseJourneyFormValues({ journey: JOURNEY.MEDICATION_REQUEST });
        setScheduleVideoConsultationFormValues(undefined);

        return navigate(EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_QUESTIONS);
      }

      return navigate(EMBEDDABLES_ONBOARDING_ROUTE.INTAKE_REFILL_REQUEST);
    }
  };

  return (
    <Form.Provider {...form}>
      <OnboardingHeader
        title={t`How would you like to kick-start your journey?`}
        variant="secondary"
      />

      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col items-center gap-14"
      >
        <div className="flex w-full max-w-xl grow flex-col gap-6">
          <Form.Field
            control={form.control}
            name="journey"
            render={({ field: { value, onChange, ...rest } }) => (
              <Form.Item>
                <div className="flex gap-2.5">
                  <Form.Control>
                    <JourneyRadioGroup
                      value={value}
                      onValueChange={onChange}
                      {...rest}
                    />
                  </Form.Control>
                </div>
                <Form.Message className="text-center text-red-03" />
              </Form.Item>
            )}
          />

          <div className="flex flex-col gap-5 text-center text-nature-02 sm:text-xl">
            <p>
              {t`Choose between first video visit or order medication directly. Flexibility is key; feel free to switch options as your treatment progresses!`}
            </p>
            <p>
              {t`Whether you are looking for branded meds or compounded, you may choose either option above!`}
            </p>
          </div>
        </div>

        <div className="flex w-full flex-col-reverse items-center gap-y-4 sm:flex-row sm:justify-between">
          <Button
            onClick={() => navigate(-1)}
            variant="cover-secondary"
            size="lg"
          >
            <AltArrowLeftIcon className="hidden size-4 sm:block" />
            {t`Back`}
          </Button>

          <Button type="submit" size="lg" variant="cover-primary">
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </form>
    </Form.Provider>
  );
};
