import type { Plan } from "@/domains/billing/models";
import {
  GOOGLE_TAG_MANAGER_EVENTS,
  handleEverflowConvertEvent,
  handleGoogleTagManagerTracking,
  handleKatalysConvertEvent,
  handlePixelConvertEvent,
  handleQuoraTracking,
  handleTatariConvertEvent,
  handleTheOfferTracking,
  QUORA_EVENTS,
  TATARI_EVENT,
  THE_OFFER_EVENTS,
} from "@/utils";

interface TrackConversionParams {
  subscriptionPlan: Plan;
  orderId: string;
  everflowOfferId?: number;
}

export const trackConversion = ({
  subscriptionPlan: { price, name, subscriptionPriceId, discount },
  orderId,
  everflowOfferId,
}: TrackConversionParams) => {
  handleEverflowConvertEvent({ amount: price, offerId: everflowOfferId });

  handlePixelConvertEvent({ value: price });

  handleQuoraTracking({
    event: QUORA_EVENTS.PURCHASE,
    options: { value: price },
  });

  handleTheOfferTracking({
    event: THE_OFFER_EVENTS.PURCHASE,
    payload: {
      order_id: orderId,
      amount: price,
    },
  });

  handleTatariConvertEvent(TATARI_EVENT.PURCHASE, { value: price });

  handleGoogleTagManagerTracking({
    event: GOOGLE_TAG_MANAGER_EVENTS.PURCHASE,
    options: {
      value: price,
      currency: "USD",
      transaction_id: orderId,
      coupon: discount?.code,
      items: [
        {
          item_id: subscriptionPriceId,
          item_name: name,
          price,
          quantity: 1,
          discount: discount?.amountOff,
          coupon: discount?.code,
        },
      ],
    },
  });

  handleKatalysConvertEvent({
    orderId,
    items: [
      {
        name,
        sku: subscriptionPriceId,
        price: price,
        discount: discount && {
          code: discount.code,
          amount: discount.amountOff,
        },
      },
    ],
  });
};
